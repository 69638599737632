import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import STLViewer from '../STLViewer/STLViewer';
import helpers from "@/global-components/components/helpers"
import PrintProduct from '../productRelated/printProduct/PrintProduct';
import { Badge } from "@/global-components/components/ui/badge";
import { ProductType } from '@/global-components/types';
import { Building, Loader } from 'lucide-react';
import ProductDelete from '@/views/product/productDelete/ProductDelete';
import GCodeViewer from '@/global-components/components/bw/gCodeViewer/GCodeViewer';
import useUserStore from '@/context/useUserStore';
import { useQuery } from '@apollo/client';
import api from '@/global-components/api';

type ProductStatus = 'unapproved' | 'approved' | 'production';

type ProductPreviewProps = {
  product: ProductType;
  navigate: Function;
  index: number;
  showOrg?: boolean;
  disabled?: boolean;
  callback?: Function;
}

const ProductPreview = (props: ProductPreviewProps) => {
  const { userPreferences } = useUserStore()
  const [wasDragging, setWasDragging] = useState<boolean>(false)
  const [printButtonPressed, setPrintButtonPressed] = useState<boolean>(false)
  const deleteProductButton = useRef<HTMLElement | null>(null)
  const numberUnreadMessages: number = props.product.messagemodelSet ?
    props.product.messagemodelSet?.filter((message: any) => !message.read)?.length : 0

  const [product, setProduct] = useState<ProductType | null>(null)
  const [firstStl, setFirstStl] = useState<any>(null)
  const [firstGcode, setFirstGcode] = useState<any>(null)

  const productFilesQuery = useQuery(api.products.queries.GET_FILES_FOR_PRODUCT, {
    variables: {
      productId: product?.productId,
      skip: !product
    }
  })

  useEffect(() => {
    setProduct(props.product)
  }, [props.product])

  useEffect(() => {
    if (productFilesQuery.data?.filesForProduct) {
      setFirstStl(productFilesQuery.data.filesForProduct.find((filemodelSet: any) => helpers.extractFileTypeFromFilename(filemodelSet.fileName) === 'stl'))
      setFirstGcode(productFilesQuery.data.filesForProduct.find((filemodelSet: any) => helpers.extractFileTypeFromFilename(filemodelSet.fileName) === 'gcode'))
    }
  }, [productFilesQuery.data])

  const mouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setWasDragging(false);
  }

  const mouseMove = (event: MouseEvent<HTMLDivElement>) => {
    setWasDragging(true);
  }


  const goToProduct = (event: MouseEvent<HTMLDivElement>) => {
    const target: HTMLElement = event.target as HTMLElement;
    if(wasDragging || printButtonPressed || deleteProductButton.current?.contains(target)) {
      return;
    } else {
      props.navigate('/product/' + props.product.productVersion[0].reference);
    }
  }

  const printButtonMouseDown = () => {
    setPrintButtonPressed(true);
  }

  const showPrintModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  }

  if (!props.product.productVersion[0]) {
    return null
  }
  
  return (
    <div className={`product-preview type normal group/productpreview hover:shadow-lg 
      ${props.showOrg ? 'bg-white' : 'bg-white'}
      ${props.disabled && 'pointer-events-none'}`} 
      onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={goToProduct} key={props.index}>
      {numberUnreadMessages > 0 ? <div className='notification-badge text-xxs font-bold bg-ui-notification-red text-white'>{numberUnreadMessages}</div> : null}
      
      <div className='absolute top-2 right-2 flex justify-end items-center gap-1 z-10'>
        <div className='opacity-0 h-fit flex items-center transition-all duration-100 group-hover/productpreview:opacity-100'>
          <ProductDelete forwardRef={deleteProductButton} reference={product?.productVersion[0].reference} buttonVariant='inline' buttonSize='zeromargin' callback={props.callback}/>
        </div>
        <Badge className={product?.statusSeen ? 'w-fit' : 'w-fit status-pulse'}
          variant={product?.status.toLowerCase() as ProductStatus}>
            {product?.status}
        </Badge>
      </div>
      
      <div className='product-preview-header text-xs'>
        
      </div>
      {userPreferences?.previewGcode ? 
        <div className={firstGcode ? 'product-preview-visualiser' : 'product-preview-visualiser no-stl'}>
          {firstGcode
            ? <GCodeViewer gcodeFile={firstGcode} nozzleDiameter={firstGcode.fileattributesmodelSet ? firstGcode.fileattributesmodelSet[0]?.nozzleSize : undefined} miniPreview />
            : <div className='text-xs text-bw-green/30'>
              {productFilesQuery.loading ? 
                <Loader className='h-4 w-4 animate-spin' />
                : <>No File to Preview</>
              }
            </div>
          }
        </div>
        :
        <div className={firstStl ? 'product-preview-visualiser' : 'product-preview-visualiser no-stl'}>
          {firstStl
            ? <STLViewer url={firstStl.presignedUrl} fileSize={firstStl.fileSize} fileName={firstStl.fileName} fileId={firstStl.fileId} miniPreview={true}/>
            : <div className='text-xs text-bw-green/30'>
              {productFilesQuery.loading ? 
                <Loader className='h-4 w-4 animate-spin' />
                : <>No File to Preview</>
              }
            </div>
          }
        </div>
      }
      <div className='product-preview-footer pl-2 pr-2 pb-1 flex flex-col gap-0'>
        <div className='product-title flex justify-between items-center gap-2'>
          {props.product.productTitle} 
          {(!props.showOrg && props.product.organisations?.length) ? 
            <span className='flex opacity-30 gap-1 items-center font-normal'>{product?.organisations?.length}<Building className='h-4 w-4' /> </span>
            : null }
        </div>
        {product?.organisations && props.showOrg ?
          <div className='product-org relative -left-0.5 gap-1'>
            {product.organisations?.length === 1 ?
              <div className='flex gap-1 opacity-70 items-center'>
                <Building className='h-4 w-4' />{product.organisations[0].name}
              </div>
              :
              <div className='flex gap-1 opacity-70 items-center'>
                <Building className='h-4 w-4' />Multiple organisations ({product.organisations.length})
              </div>
            }
          </div> : null
        }
      </div>
    </div>
  )
}

export default ProductPreview