import React, { useEffect, useState, useRef, KeyboardEvent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from "react-cssfx-loading";

import helpers from "@/global-components/components/helpers"
import { Button } from "@/global-components/components/ui/button"
import { Input } from "@/global-components/components/ui/input"
import { MessageCircle, Send, Paperclip, Loader } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import DownloadButton from '@/global-components/components/bw/DownloadButton'
import { User } from '@/global-components/types'
import api from '@/global-components/api'

import './MessagesPopover.scss';
import useUserStore from '@/context/useUserStore';

const MessagesPopover = (props: any) => {
  const { user } = useUserStore()
  const productId: string = props?.productId;
  const productReference: string = props.productReference;

  const [open, setOpen] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [messageInput, setMessageInput] = useState<string>('');
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);

  const [sendMessageToApi, sendMessageResult] = useMutation(api.messages.mutations.ADD_MESSAGE_TO_PRODUCT);
  const [sendMessageWithFileToApi, sendMessageWithFileResult] = useMutation(api.messages.mutations.ADD_MESSAGE_WITH_FILE_TO_PRODUCT);
  const [markMessageAsRead, markMessageAsReadResult] = useMutation(api.messages.mutations.MARK_MESSAGE_AS_READ);
  const messagesQuery = useQuery(api.messages.queries.GET_MESSAGES_FOR_PRODUCT, {
    variables: { productReference },
    pollInterval: 5000,
    skip: !productReference
  });
  
  const sendMessage = () => {
    setSendingMessage(true);
    if (filesToUpload.length) {
      sendMessageWithFileToApi({ variables: { productId: productId, message: messageInput, file: filesToUpload[0], emailNotifications: true}})
        .then((result: any) => {
          setMessageInput('');
          setFilesToUpload([]);
          messagesQuery.refetch();  
          setSendingMessage(false);
        })
        .catch((error : any) => { 
          setSendingMessage(false);
        })
    } else {
      sendMessageToApi({ variables: { productId: productId, message: messageInput, emailNotifications: true }})
        .then((result: any) => {
          setMessageInput('');
          messagesQuery.refetch();  
          setSendingMessage(false);
        })
        .catch((error : any) => { 
          setSendingMessage(false);
        })
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleMessagesInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilesToUpload(Array.from(event.target.files ? event.target.files : []));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest"})
  }

  const openListener = (value: boolean) => {
    setOpen(value);
    if(value) {
      messages.forEach((message: any) => {
        if (!message.read && message.sender.userId !== user?.userId) {
          markMessageAsRead({ variables: { messageId: message.messageId}})
          .then((result: any) => {
            //
          })
          .catch((error : any) => { 
            
          })
        }
      })
    }
  }

  const handleUploaderClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (messagesQuery.data?.messagesForProduct.length !== messagesQuery.previousData?.messagesForProduct?.length) {
      scrollToBottom();
    }
    setMessages(messagesQuery?.data?.messagesForProduct);
    setUnreadMessages(messages?.filter((message: any) => !message.read && message.sender.userId !== user?.userId )?.length);
  }, [messagesQuery, messages]);

  useEffect(() => {
    messagesQuery.refetch();
  }, [open]);

  return (
    <Popover open={open} onOpenChange={openListener}>
      <PopoverTrigger className='relative' asChild >
        <Button variant="bwsecondary" className='relative' size="icon">
          {unreadMessages 
            ? <div className='notification-badge text-xxs font-bold bg-ui-notification-red text-white'>{unreadMessages}</div>
            : null}
          {messagesQuery.loading ? 
            <Loader className="h-4 w-4 animate-spin" />
            :
            <MessageCircle className='h-4 w-4' />
          }
        </Button>
      </PopoverTrigger>
        <PopoverContent className="w-96 pt-0 popover-content max-h-96 relative flex flex-col gap-4" align='end' onOpenAutoFocus={scrollToBottom}>
            <div className="max-h-full overflow-y-auto pr-4 pt-4 text-bw-green">
              {messages?.length ? 
                <div className='messages flex flex-col gap-8 max-h-full' ref={messagesEndRef}>
                  {messages.map((message: any, index: number) => {
                    return  <div className={message.sender.username === user?.username ? 'grid gap-1 message right self-end' : 'grid gap-1 message'} key={index}>
                              <div className={message.sender.username === user?.username ? 'text-xs text-right flex gap-2 justify-end mr-8' : 'text-xs flex gap-2 ml-8'}>
                                <div className="font-bold">{message.sender.username}</div>
                                <div className='text-bw-green/30'>{helpers.dateAndTime(message.datetime)}</div>
                              </div>
                              <div className='message-content bg-bw-pale-sage/50'>{message.message}</div>
                              {message.file ? 
                                <div className={message.sender.username === user?.username ? 'w-full flex justify-end' : 'w-full flex justify-start'}>
                                  <DownloadButton url={message.file.presignedUrl} name={message.file.fileName} link />
                                </div>
                                : null}
                            </div>
                  })}
                </div>
                :
                <div className='text-sm text-bw-grey w-full text-center'>No messages yet.</div>
              }
            </div>
            <div className="flex gap-2">
              <div className='flex gap-2 w-full flex-none flex-shrink'>
                <Input type='file' onChange={handleFileInputChange} ref={fileInputRef} className='flex-1 hidden'/>
                <Button variant='ghost' className='flex gap-2' onClick={handleUploaderClick}><Paperclip className='h-4 w-4' />{filesToUpload.length ? filesToUpload.length : null}</Button>
                <Input
                  className="text-bw-green placeholder-gray-200"
                  placeholder="Your message ..."
                  type='text'
                  value={messageInput}
                  onChange={handleMessagesInputChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <Button disabled={messageInput.length <= 0 || sendingMessage} className='flex-none' variant='bwprimary' size='icon' onClick={sendMessage}>
                {sendingMessage ? <Loader className="h-4 w-4 animate-spin" /> : <Send className='h-4 w-4'></Send>}
              </Button>
            </div>
        </PopoverContent>
    </Popover>
  )
}

export default MessagesPopover;