import { Button } from "@/global-components/components/ui/button";
import { Checkbox } from "@/global-components/components/ui/checkbox"
import PrintProduct from './printProduct/PrintProduct';
import api from '@/global-components/api'
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from "@/global-components/components/ui/use-toast";
import { CircleDollarSign, Diameter, Eye, Minus, MinusCircle, Route, Snail, Timer, Weight, X, XCircle } from "lucide-react";
import DownloadButton from '@/global-components/components/bw/DownloadButton'
import { Spin } from "react-cssfx-loading";
import { FileAtrributes, FileType } from "@/global-components/types";
import { useEffect, useState } from "react";
import FileTag, { FileExtensionType } from "@/global-components/components/bw/FileTag";
import helpers from "@/global-components/components/helpers";
import usePreviewStore from "@/context/usePreviewStore";
import AutomationSettings from "./AutomationSettings";


type GcodeFileInfo = {
  file: FileType;
  showActions: boolean;
  slicerSettingsOpen?: boolean;
  slicedExternally?: boolean;
  user?: any;
  index?: number;
  updating?: boolean;
  callback?: Function;
  removeFileFromPart?: Function;
  product?: any;
  showDetails?: boolean;
  showCheckbox?: boolean;
  inPart?: boolean;
  noEdit?: boolean;
  viewOnly?: boolean
  view?: Function;
}

const GcodeFileInfo = (props: GcodeFileInfo) => {
  const { fileIdPreviewed } = usePreviewStore()
  const [file, setFile] = useState<FileType | undefined>(props.file)
  const [fileAttributes, setFileAttributes] = useState<FileAtrributes | undefined>(undefined)

  const user: any = props.user;
  const index: number | undefined = props.index;
  const showActions: boolean = props.showActions;
  const showCheckbox: boolean = props.showCheckbox ? true : false;
  const callback: Function | undefined = props.callback;
  const showDetails: boolean | undefined = props.showDetails;
  const product: any | undefined = props.product;

  const { toast } = useToast()
  const fileAttributesQuery = useQuery(api.products.queries.GET_FILE_ATTRIBBUTES_FOR_FILE, {
    variables: {
      fileId: file?.fileId,
      skip: !file
    }
  })
  const [deleteFileOnServer, deleteFileOnServerResults] = useMutation(api.products.mutations.DELETE_FILE);
  const [removeFileFromProductMutation, { loading: removingFile }] = useMutation(api.products.mutations.REMOVE_FILE_FROM_PRODUCT)

  useEffect(() => {
    setFile(props.file)
    fileAttributesQuery.refetch()
  }, [props.file])

  useEffect(() => {
    if (fileAttributesQuery.data?.fileAttributesForFile) {
      setFileAttributes(fileAttributesQuery.data?.fileAttributesForFile)
    }
  }, [fileAttributesQuery.data])

  /* potentially depricated */
  const deleteFile = (file: any) => {
    removeFileFromProductMutation({variables: {fileId: file.fileId, productId: product.productId}})
      .then((result: any) => {
        if (result.data.removeFileFromProduct.success) {
          if (callback) {
            callback(file.fileId);
          }
          toast({
            title: 'File removed from product',
            variant: 'success',
            duration: 3000,
          })
        }
      })
      .catch((error : any) => { 
        toast({
          title: 'Remove failed',
          variant: 'destructive',
          duration: 5000,
          description: "We're sorry, but unfortunately '" + file.fileName + "' could not be deleted. Please try again or contact software@batch.works, or message staff from within the product.",
        })
        
      })
  }

  const minutesToMMHH = (minutes: string | number): string => {
    const minutesAsNumber: number = typeof minutes === 'string' ? parseFloat(minutes) : minutes;
    const h: number = minutesAsNumber >= 60 ? Math.floor(minutesAsNumber/60) : 0
    const m: number = minutesAsNumber % 60
    return h.toString() + ':' + m.toString()
  }

  if (!file) {
    return null
  }

  return (
		<div
			className={`file-preview relative group/file flex justify-between items-center rounded-md border border-bw-green/10 hover:border-bw-green/20 bg-white px-4 py-3 font-mono text-sm ${
				props.updating && 'opacity-30'
			}`}
			key={index}
		>
			<div className="file-info relative max-w-full">
				<div className="flex items-center gap-2">
					<FileTag type={helpers.extractFileTypeFromFilename(file.fileName) as FileExtensionType} />
					<div className='whitespace-nowrap truncate'>{file.fileName}</div>
					{props.updating && (
						<Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
					)}
				</div>
				{showDetails ? (
					fileAttributes ? (
						<div className="text-xs text-bw-green flex items-center gap-3 mt-1">
							<span className="flex gap-1 items-center" title="Nozzle diameter">
								<Diameter className="h-4 w-4 opacity-30" /> {fileAttributes.nozzleSize}mm
							</span>
							<span className="flex gap-1 items-center" title="Estimated printing time">
								<Timer className="h-4 w-4 opacity-30" />
								{minutesToMMHH(fileAttributes.printTime)}h
							</span>
							<span className="flex gap-1 items-center" title="Estimated material weight">
								<Weight className="h-4 w-4 opacity-30" />
								{fileAttributes.printWeight}g
							</span>
							{file.costmodelSet ? (
								<span className="flex gap-1 items-center" title="Estimated cost">
									<CircleDollarSign className="h-4 w-4 opacity-30" /> £{file.costmodelSet[0]?.grossPrice}
								</span>
							) : null}
							{fileAttributes.outOfBounds && <span className="flex gap-1 text-ui-denial-red">| Out Of Bounds</span>}
						</div>
					) : (
						<div className="text-xs flex items-center gap-1">
							<Spin className="inline-spin h-2 w-2" color="#36463D" width="20px" height="20px" duration="0.3s" />{' '}
							Loading file attributes
						</div>
					)
				) : null}
			</div>
			{showActions ? (
				<div className="flex gap-2 absolute right-2 bg-white opacity-0 pointer-events-none group-hover/file:opacity-100 group-hover/file:pointer-events-auto">
					{!props.noEdit &&
						(!props.inPart ? (
							<Button
								className={`${props.viewOnly && 'hidden'}`}
								variant="destructiveoutline"
								size="smallicon"
								title="Delete file"
								onClick={() => deleteFile(file)}
							>
								<XCircle className="h-4 w-4" />
							</Button>
						) : (
							<Button
								className={`${props.viewOnly && 'hidden'}`}
								variant="warnoutline"
								title="Remove file from part"
								size="smallicon"
								onClick={() => props.removeFileFromPart && props.removeFileFromPart(file.fileId)}
							>
								<Minus className="h-4 w-4" />
							</Button>
						))}
					{/* {!props.slicedExternally && 
            <Button variant='outline' size='sm' className='gap-2 text-xs' onClick={() => callback ? callback() : null}>
              {props.slicerSettingsOpen ? 
                <X className="h-4 w-4" /> 
                : <Route className='h-4 w-4' />} 
              {props.slicerSettingsOpen ? 
                'Settings' 
                : 'Settings'}
            </Button>
          } */}
          {!props.noEdit &&
            <AutomationSettings file={file} product={product}>
              <Button variant="outline" aria-label="Automation Settings" className="" size='smallicon'>
                <Snail className="h-4 w-4" />
              </Button> 
            </AutomationSettings>
          }
					{showCheckbox ? null : <DownloadButton url={file.presignedUrl} name={file.fileName} />}
					<Button variant="outline" className={`${props.noEdit && 'hidden'}`} size="smallicon" onClick={() => (props.view ? props.view(file.fileId) : null)}>
						<Eye className="h-4 w-4" />
					</Button>
				</div>
			) : null}
			{showCheckbox ? (
				<div>
					<Checkbox onCheckedChange={(checked: boolean) => (callback ? callback(checked) : null)} />
				</div>
			) : null}
			{fileIdPreviewed === file.fileId && (
				<div className="absolute flex items-center justify-center w-1 h-full top-0 right-7 group-hover/file:opacity-0">
					<div className="h-1 w-1 rounded-full bg-bw-green/70"></div>
				</div>
			)}
		</div>
	);
}

export default GcodeFileInfo;
