import React, { useState, ChangeEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/global-components/components/ui/button'
import { Input } from '@/global-components/components/ui/input'
import { Textarea } from '@/global-components/components/ui/textarea'
import { useToast } from '@/global-components/components/ui/use-toast'
import helpers from '@/global-components/components/helpers'
import { FilamentType } from '@/global-components/types'
import Filament from '@/global-components/components/bw/filament/Filament'
import Circle from '@uiw/react-color-circle'
import { AlertCircle, Download, Layers, Loader } from 'lucide-react'
import { Spin } from 'react-cssfx-loading'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogFooter, DialogTitle, DialogTrigger } from '@/global-components/components/ui/dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/global-components/components/ui/select'

import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo'

import { useMutation, useQuery } from '@apollo/client'
import api from '@/global-components/api'
import { Label } from '@/global-components/components/ui/label'

interface ProductForm {
	productTitle: string
	productDescription: string
}

const PrintProduct = (props: any) => {
	const [uploading, setUploading] = useState<boolean>(false)
	const [open, setOpen] = useState(false)
	const [quantity, setQuantity] = useState(1)
	const [availableFilaments, setAvailableFilaments] = useState<FilamentType[]>([])
	const [selectedFilamentId, setSelectedFilamentId] = useState<string | undefined>(undefined)
	const product: any = props.product
	const gcodeFiles: any = props.gcodeFiles
	const [gcodeFileIdToPrint, setGcodeFileIdToPrint] = useState<string | undefined>(undefined)
	const smallButton: boolean = props.smallButton ? true : false
	const { toast } = useToast()
	const navigate = useNavigate()

	const filamentsQuery = useQuery(api.printers.queries.GET_AVAILABLE_FILAMENTS, {
		skip: !open
	})

	const [createPrintRequest, { loading, error }] = useMutation(api.printRequests.mutations.CREATE_PRINT_REQUEST, {
		onCompleted: (result: any) => {
			if (result.printRequest.success) {
				setUploading(false)
				setOpen(false)
				toast({
					variant: 'success',
					title: 'Print sent',
					duration: 3000,
				})
				// optimizeScheduleFromRequest({variables: {requestId: result.printRequest.printRequest.requestId, forcePrint: true}})
			} else {
				toast({
					variant: 'destructive',
					title: 'Something went wrong while creating a print request',
					description: result.printRequest.errors[0],
					duration: 8000,
				})
			}
		},
		onError: (err: any) => {
			setUploading(false)
			toast({
				variant: 'destructive',
				title: 'Sorry, something is not working right now',
				description: err.message,
			})
		},
	})

	const [optimizeScheduleFromRequest] = useMutation(api.printRequests.mutations.OPTIMIZE_SCHEDULE_FROM_REQUEST, {
		onCompleted: (result: any) => {
			if (result.optimizeScheduleFromRequest.success) {
				setUploading(false)
				setOpen(false)
				toast({
					variant: 'success',
					title: 'Print sent',
					duration: 3000,
				})
			} else {
				setUploading(false)
				toast({
					title: 'Something went wrong while optimizing the schedule',
					description: result.optimizeScheduleFromRequest.error,
				})
			}
		},
		onError: (err: any) => {
			setUploading(false)
			toast({
				variant: 'destructive',
				title: 'Sorry, something is not working right now while optimising',
				description: err.message,
				duration: 10000,
			})
		},
	})

	const handleQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setQuantity(Number(event.target.value))
	}

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setUploading(true)
		createPrintRequest({
			variables: { fileId: gcodeFileIdToPrint, filamentId: selectedFilamentId, factoryId: 1, quantity: quantity, productId: product.productId },
		})
	}

	useEffect(() => {
		if (filamentsQuery.data) {
			setAvailableFilaments(filamentsQuery.data.filament)
		}
	}, [filamentsQuery])

	return (
		<Dialog
			open={open}
			onOpenChange={(value: boolean) => {
				setOpen(value)
				if (props.onOpenChange) {
					props.onOpenChange(value)
				}
			}}>
			<DialogTrigger asChild>
				{smallButton ? (
					<Button
						variant='bwprimary'
						disabled
						size='sm'
						onMouseDown={props.onMouseDownCallBack}
						onMouseUp={props.onMouseUpCallBack}
						title='Printing is currently disabled.'>
						{props.buttonTitle}
					</Button>
				) : (
					<Button
						variant='bwprimary'
						disabled={props.disabled}
						onMouseDown={props.onMouseDownCallBack}
						onMouseUp={props.onMouseUpCallBack}
						title='Printing is currently disabled.'>
						<Layers className='h-4 w-4' /> {props.buttonTitle}
					</Button>
				)}
			</DialogTrigger>
			<DialogContent className='max-w-64'>
				<DialogHeader>
					<DialogTitle>
						{product.status.toLowerCase() === 'production' ? <span className='font-normal'>Print</span> : <span className='font-normal'>Test print</span>}:{' '}
						<span className='font-bold'>{product?.productTitle}</span>{' '}
					</DialogTitle>
				</DialogHeader>
				<form onSubmit={onSubmit}>
					<div className='mb-4'>
						<div className='flex gap-4'>
							<div className='flex flex-col gap-4 flex-1'>
								<div>
									<Select onValueChange={setGcodeFileIdToPrint}>
										<SelectTrigger className='basis-4/6'>
											<SelectValue placeholder='Select GCode from Product' />
										</SelectTrigger>
										<SelectContent className='max-w-full'>
											{gcodeFiles?.map((gcode: any, index: number) => (
												<SelectItem value={gcode.fileId} key={index}>
													<div className='flex items-center text-left leading-tight gap-2'>{gcode.fileName}</div>
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div className='flex gap-1'>
									<Select onValueChange={setSelectedFilamentId}>
										<SelectTrigger className='basis-4/6'>
											<SelectValue placeholder='Select Filament' />
										</SelectTrigger>
										<SelectContent>
											{availableFilaments.map((filament: FilamentType, index: number) => (
												<SelectItem value={filament.filamentId} key={index}>
													<div className='flex items-center gap-2'>
														<Filament color={filament.colour.hexCode || '#333333'} active={true} small={true} /> {filament.material.displayName} -{' '}
														{filament.colour.displayName}
													</div>
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<Input
										type='number'
										disabled={product.status.toLowerCase() !== 'production'}
										value={quantity}
										placeholder='Set Quantity'
										onChange={handleQuantityChange}
										className='basis-2/6'
									/>
								</div>
								<Select value='ssf'>
									<SelectTrigger className='w-full'>
										<SelectValue placeholder='Please select a Location' />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value='ssf'>BW London</SelectItem>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div>
					<div className='grid grid-cols-3 mb-8 hidden'>
						<div className=''>
							<div className='text-xs text-bw-grey font-medium'>Estimated Cost</div>
							0.00 £
						</div>
						<div className=''>
							<div className='text-xs text-bw-grey font-medium'>Estimated CO2e</div>
							0.00 kg
						</div>
						<div className=''>
							<div className='text-xs text-bw-grey font-medium'>Estimated Time Finished</div>
							n/a
						</div>
					</div>
					<DialogFooter>
						<div className='flex gap-1 items-center'>
							{quantity > 1 && (
								<Button className='flex gap-1 items-center' disabled variant='bwsecondary'>
									<Download className='h-4 w-4' />
									Download automation GCode
								</Button>
							)}
							<Button variant='bwconfirm' className='flex gap-1' disabled={uploading} type='submit'>
								{uploading ? <Loader className='h-4 w-4 animate-spin' /> : null}
								Send {product.status.toLowerCase() !== 'production' ? 'test print' : null} to factory
							</Button>
						</div>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default PrintProduct
